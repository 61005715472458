import {ID, Response} from '../../../../../_metronic/helpers'
import {TechnicalAdvisorCommand, TechnicalAdvisorDto} from "../../../../../services/requests";


export const initialTechnicalAdvisor: TechnicalAdvisorCommand = {
  employeeId: "",
  langs: ['FR'],
  technicalWords: [],
  lineOfBusiness: "",
  name: "",
  email: '',
  region: TechnicalAdvisorCommand.region.QUEBEC,
  active: true,
  fulltimeAvailable: false
}
