/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {TechnicalAdvisorDto} from "../../../../../../services/requests";
import {Link} from "react-router-dom";

type Props = {
  technicalAdvisor: TechnicalAdvisorDto
}

const TechnicalAdvisorNameCell: FC<Props> = ({technicalAdvisor}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
        <Link to='/admin/technical-advisors/edit' className='text-dark fw-bold text-hover-primary fs-6' state={{technicalAdvisorId: technicalAdvisor.id, lineOfBusinessId: technicalAdvisor.lineOfBusiness}} >{technicalAdvisor.name}</Link>
        <span>{technicalAdvisor.employeeId}</span>
    </div>
  </div>
)

export {TechnicalAdvisorNameCell}
