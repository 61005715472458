import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery} from "react-query";
import {
    JobPositionService,
    LineOfBusinessService, TechnicalAdvisorSAcceptanceSkillService,
    TechnicalAdvisorService,
    TechnicalWordService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {AcceptanceSkills} from "./components/AcceptanceSkills";
import {PageTitle} from "../../../../_metronic/layout/core";
import {BlockingInfos} from "./components/BlockingInfos";
import {useAuth} from "../../../modules/auth";

type Params = {
  technicalAdvisorId?: ID,
  lineOfBusinessId: string
}

const TechnicalAdvisorEditPage: FC = () => {
  const location = useLocation();
  const locationState = location.state as Params;
  const intl = useIntl()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const {currentUser} = useAuth()

    const isCurrentUserAdmin = !!currentUser?.roles?.find(role => role == "ROLE_ADMIN");
    const [technicalAdvisorId, setTechnicalAdvisorId] = useState(locationState.technicalAdvisorId);


  const {data: lineOfBusiness, isLoading: isLineOfBusinessLoading} = useQuery({
        queryKey: ['lineOfBusiness', locationState.lineOfBusinessId],
        queryFn: () => {
          return LineOfBusinessService.findById4(locationState.lineOfBusinessId);
        }
      }
  )

    const {
        isLoading: isTechnicalAdvisorLoading,
        data: technicalAdvisor,
    } = useQuery({
            queryKey: ['technicalAdvisor', technicalAdvisorId],
            queryFn: () => {
                return TechnicalAdvisorService.findById1(technicalAdvisorId as string);
            },
            enabled: !!technicalAdvisorId
        }
    )

    const { data: technicalWords, isLoading: isTechnicalWordsLoading } = useQuery({
        queryKey: ['technicalWords', locationState.lineOfBusinessId],
        queryFn: () => {
            // Do something here

            return TechnicalWordService.findAllByLineOfBusiness(locationState.lineOfBusinessId);
        }
    });

    const { data: jobPositions, isLoading: isJobPositionsLoading } = useQuery({
        queryKey: ['jobPositions', locationState.lineOfBusinessId],
        queryFn: () => {
            // Do something here

            return JobPositionService.findAllByLineOfBusiness2(locationState.lineOfBusinessId);
        }
    });

    const { data: acceptanceSkills, isLoading: isAcceptaceSkillsLoading } = useQuery({
        queryKey: ['acceptanceSkills', technicalAdvisorId],
        queryFn: () => {
            // Do something here

            return TechnicalAdvisorSAcceptanceSkillService.findAllByTechnicalAdvisor(technicalAdvisorId as string);
        },
        enabled: !!technicalAdvisorId
    });

    const everythingLoaded = lineOfBusiness
        && (!technicalAdvisorId || (technicalAdvisor && acceptanceSkills))
        && jobPositions && technicalWords;


    const pageTitle = technicalAdvisor ?
        intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.TITLE.UPDATE'}, {fullName: technicalAdvisor?.name})
        : intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.TITLE.NEW'});

    const refreshTechnicalAdvisorId = (newTechnicalAdvisorId: string) => {
        setTechnicalAdvisorId(newTechnicalAdvisorId);
    }


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const changeTab = (newTabIndex: number) => {
        console.log(newTabIndex)
      stepper.current?.goto(newTabIndex)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
      <>
        <PageTitle breadcrumbs={[]}>
            {pageTitle}
        </PageTitle>
        <div className='card mb-5'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-5'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav' onClick={() => changeTab(1)} role="button">
              <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.MENU.MAIN_INFOS'})}</h3>
            </div>

                <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(2)} role="button">
                      {!!technicalAdvisorId && (
                    <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.MENU.ACCEPTANCE_SKILLS'})}</h3>
                      )}
                </div>

              {isCurrentUserAdmin && (
                  <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(3)} role="button">
                      {!!technicalAdvisorId && (
                          <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISOR_EDIT.MENU.BLOCKING_INFOS'})}</h3>
                      )}
                  </div>
              )}

          </div>
            {everythingLoaded && (
            <div className='mx-auto mw-600px w-100 pt-5'>
                <div className='current' data-kt-stepper-element='content'>
                  <MainInfos refreshTechnicalAdvisorId={refreshTechnicalAdvisorId} technicalAdvisor={technicalAdvisor} lineOfBusiness={lineOfBusiness} technicalWords={technicalWords} />
                </div>
                <div data-kt-stepper-element='content'>
                    {technicalAdvisor && acceptanceSkills && (
                        <AcceptanceSkills technicalAdvisor={technicalAdvisor} lineOfBusiness={lineOfBusiness} jobPositions={jobPositions} activeAcceptanceSkills={acceptanceSkills} />
                    )}
                </div>
                {isCurrentUserAdmin && (
                    <div data-kt-stepper-element='content'>
                        {technicalAdvisor  && (
                            <BlockingInfos technicalAdvisor={technicalAdvisor} />
                        )}
                    </div>
                )}
            </div>
            )}
        </div>
      </div>
    </div>
    </>

  )
}

export {TechnicalAdvisorEditPage}
