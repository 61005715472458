/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetTechnicalAdvisorsForInterview } from '../models/GetTechnicalAdvisorsForInterview';
import type { TechnicalAdvisorForInterview } from '../models/TechnicalAdvisorForInterview';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BusinessLogicInterviewTechnicalAdvisorService {

    /**
     * @param requestBody 
     * @returns TechnicalAdvisorForInterview OK
     * @throws ApiError
     */
    public static getTechnicalAdvisorsForInterview(
requestBody: GetTechnicalAdvisorsForInterview,
): CancelablePromise<Array<TechnicalAdvisorForInterview>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/business-logic/interview-technical-advisor/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
