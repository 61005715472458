import clsx from "clsx";
import {
    GetTechnicalAdvisorsForInterview,
    JobPositionDto,
    LineOfBusinessDto,
    TechnicalWordDto
} from "../../../../services/requests";
import {FormattedMessage, useIntl} from "react-intl";
import Select from "react-select";
import React, {FC} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

export type InterviewContentQuestionsSearchFormValues = {
    lineOfBusiness: string
    jobPosition: string
    lang: string
    levelOfExpertise: string
}

type Props = {
    lineOfBusinesses: LineOfBusinessDto[]
    jobPositions?: JobPositionDto[]
    currentLineOfBusiness?: string
    onLineOfBusinessChange: Function
    onSubmit: SubmitHandler<any>
}

export const SearchForm: FC<Props> = ({lineOfBusinesses,  jobPositions, currentLineOfBusiness, onLineOfBusinessChange, onSubmit}) => {
    const intl = useIntl()
    const methods = useForm({
        mode: "onSubmit",
        defaultValues: {
            lineOfBusiness: currentLineOfBusiness,
            jobPosition: undefined,
            lang: undefined,
            levelOfExpertise: undefined
        },
    });

    const {formState: { errors }} = useForm();
    const onSubmitInternal = (values) => {
        onSubmit( {
            lineOfBusiness: values.lineOfBusiness,
            jobPosition: values.jobPosition.value,
            lang: values.lang.value,
            levelOfExpertise: values.levelOfExpertise.value
        })

    }

    const allRequiredProperties = !!methods.watch("lang") && !!methods.watch("levelOfExpertise") && !!methods.watch("jobPosition")

    return (
        <>
            <form className='form' onSubmit={methods.handleSubmit(onSubmitInternal)}>
                    <div>
                        <div className='row mb-10'>
                            <div className='col-lg-6 col-xl-4 py-1 px-1'>
                                <select
                                    {...methods.register("lineOfBusiness", {
                                        onChange: e => onLineOfBusinessChange(e.target.value)
                                    })}
                                    name="lineOfBusiness"
                                    className='form-select form-select-solid'
                                >
                                    <option value="">{intl.formatMessage({id: 'FORM.INTERVIEW_CONTENT_QUESTIONS_SEARCH.LINE_OF_BUSINESS'})} *</option>
                                    {lineOfBusinesses.map((lineOfBusiness) => (
                                        <option key={lineOfBusiness.id} value={lineOfBusiness.id}>{lineOfBusiness.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-lg-6 col-xl-4 py-1 px-1'>
                                <Controller
                                    name="jobPosition"
                                    control={methods.control}
                                    render={({field }) => (
                                        <Select
                                            {...field}
                                            placeholder={intl.formatMessage({id: 'FORM.INTERVIEW_CONTENT_QUESTIONS_SEARCH.JOB_POSITION'})}
                                            options={jobPositions?.map(jobPosition => {
                                                return {
                                                    value: jobPosition.id,
                                                    label: jobPosition.name
                                                };})}
                                        />
                                    )}
                                />
                            </div>
                            <div className='col-lg-3 col-xl-2 py-1 px-1'>
                                <Controller
                                    name="levelOfExpertise"
                                    control={methods.control}
                                    render={({field }) => (
                                        <Select
                                            {...field}
                                            placeholder={intl.formatMessage({id: 'FORM.INTERVIEW_CONTENT_QUESTIONS_SEARCH.LEVEL_OF_EXPERTISE'})}
                                            options={Object.keys(GetTechnicalAdvisorsForInterview.levelOfExpertise)?.map(levelOfExpertise => {
                                                return {
                                                    value: levelOfExpertise,
                                                    label: intl.formatMessage({id: "ENUM.LEVEL_OF_EXPERTISE"}, { levelOfExpertise: levelOfExpertise })
                                                };})}
                                        />
                                    )}
                                />
                            </div>
                            <div className='col-lg-3 col-xl-2 py-1 px-1'>
                                <Controller
                                    name="lang"
                                    control={methods.control}
                                    render={({field }) => (
                                        <Select
                                            {...field}
                                            placeholder={intl.formatMessage({id: 'FORM.INTERVIEW_CONTENT_QUESTIONS_SEARCH.LANG'})}
                                            options={Object.keys(GetTechnicalAdvisorsForInterview.lang)?.map(lang => {
                                                return {
                                                    value: lang,
                                                    label: intl.formatMessage({id: "ENUM.LANG"}, { lang: lang })
                                                };})}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                <div className='row'>
                    <div className='col-lg-12 text-center'>
                        <button type='submit' className='btn btn-primary me-2' disabled={!allRequiredProperties}>
                            <span className='indicator-label'>{intl.formatMessage({id: 'FORM.INTERVIEW_CONTENT_QUESTIONS_SEARCH.SEARCH'})}</span>
                        </button>
                    </div>
                    {!allRequiredProperties && (
                        <div className='col-lg-12 text-center text-info'>
                            {intl.formatMessage({id: 'FORM.INTERVIEW_CONTENT_QUESTIONS_SEARCH.MESSAGE.SPECIFY_ALL_REQUIRED_ARGS'})}
                        </div>
                    )}
                </div>
            </form>
        </>
    )
}

