import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Formik, FormikValues, useFormik} from 'formik'
import {ID, isNotEmpty} from '../../../../../_metronic/helpers'
import {initialTechnicalAdvisor} from '../core/_models'
import clsx from 'clsx'
import {
    GetTechnicalAdvisorsForInterview, JobPositionService, LineOfBusinessDto, LineOfBusinessService,
    TechnicalAdvisorCommand,
    TechnicalAdvisorDto, TechnicalAdvisorSAcceptanceSkillService,
    TechnicalAdvisorService, TechnicalWordDto, TechnicalWordService
} from "../../../../../services/requests";
import {FormattedMessage, useIntl} from "react-intl";
import Select from "react-select";
import {useQuery} from "react-query";
import {useAuth} from "../../../../modules/auth";

type Props = {
    technicalAdvisor?: TechnicalAdvisorDto
    lineOfBusiness: LineOfBusinessDto
    technicalWords: TechnicalWordDto[]
    refreshTechnicalAdvisorId: Function
}

const editTechnicalAdvisorSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Name is required'),
    langs: Yup.array()
        .min(1),
    nbHoursInterviewsCurrentYear: Yup.number()
        .min(0)
})
const MainInfos: FC<Props> = ({refreshTechnicalAdvisorId, technicalAdvisor, lineOfBusiness, technicalWords}) => {

    const intl = useIntl()





    const [technicalAdvisorForEdit] = useState<TechnicalAdvisorCommand>({
        ...technicalAdvisor,
        active: !!technicalAdvisor ? technicalAdvisor.active : initialTechnicalAdvisor.active,
        lineOfBusiness: technicalAdvisor?.lineOfBusiness || lineOfBusiness.id as string,
        name: technicalAdvisor?.name || initialTechnicalAdvisor.name,
        email: technicalAdvisor?.email || initialTechnicalAdvisor.email,
        employeeId: technicalAdvisor?.employeeId || initialTechnicalAdvisor.employeeId,
        fulltimeAvailable: technicalAdvisor?.fulltimeAvailable || initialTechnicalAdvisor.fulltimeAvailable,
        langs: technicalAdvisor?.langs || initialTechnicalAdvisor.langs,
        technicalWords: technicalAdvisor?.technicalWords || initialTechnicalAdvisor.technicalWords,
        region: technicalAdvisor?.region || initialTechnicalAdvisor.region,

    })


    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
        }
    }




    const submitForm = async (values, {setSubmitting}) => {

            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await TechnicalAdvisorService.update1(values.id as string, values)
                } else {
                    const res = await TechnicalAdvisorService.create1(values)
                    refreshTechnicalAdvisorId(res.id)

                }
            } catch (ex) {
                console.error(ex)
            } finally {
                //setSubmitting(true)
                //cancel(true)
            }
    }

    return (
        <>
            <Formik
                initialValues={technicalAdvisorForEdit} validationSchema={editTechnicalAdvisorSchema} onSubmit={submitForm}
                >
                {props => (
            <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                >
                    {technicalAdvisor && technicalAdvisor.blocked && (
                        <div className="text-center text-danger w-100 mb-5 fw-bold">
                            <i className="bi bi-exclamation-triangle text-danger px-3"></i>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.INFO_IS_BLOCKED'})}
                            <div>{technicalAdvisor.blockedComment}</div>
                            <input
                                {...props.getFieldProps('active')}
                                type="hidden"
                                name="active"
                            />
                        </div>

                    )}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.LINE_OF_BUSINESS'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            type='text'
                            name='lineOfBusinessLabel'
                            value={lineOfBusiness?.name}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0'
                            )}
                            autoComplete='off'
                            readOnly={true}

                        />
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.NAME'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('name')}
                            type='text'
                            name='name'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.name && props.errors.name},
                                {
                                    'is-valid': props.touched.name && !props.errors.name,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.name && props.errors.name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.name}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.EMPLOYEE_ID'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('employeeId')}
                            type='text'
                            name='employeeId'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.employeeId && props.errors.employeeId},
                                {
                                    'is-valid': props.touched.employeeId && !props.errors.employeeId,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.employeeId && props.errors.employeeId && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.employeeId}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.EMAIL'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('email')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.email && props.errors.email},
                                {
                                    'is-valid': props.touched.email && !props.errors.email,
                                }
                            )}
                            type='email'
                            name='email'
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {/* end::Input */}
                        {props.touched.email && props.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{props.errors.email}</span>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.LANGS'})}</label>

                        <div>
                            {Object.keys(GetTechnicalAdvisorsForInterview.lang)?.map((lang) => (
                                <label className='form-check form-check-inline form-check-solid me-5'>
                                    <input
                                        className='form-check-input'
                                        {...props.getFieldProps('langs')}
                                        defaultChecked={!!technicalAdvisorForEdit.langs.find(elt => elt == lang)}
                                        name='langs'
                                        type='checkbox'
                                        value={lang}

                                    />
                                    <span className='fw-bold ps-2 fs-6'><FormattedMessage id="ENUM.LANG" values={{ lang: lang }} /></span>
                                </label>
                            ))}
                        </div>
                        {props.touched.langs && props.errors.langs && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.langs}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Label */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.REGION'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <select
                            className='form-select form-select-solid'
                            {...props.getFieldProps('region')}
                            name="region"
                        >
                            <option value="">{intl.formatMessage({id: 'COMMON.CHOOSE_IN_LIST'})}</option>
                            {Object.keys(TechnicalAdvisorCommand.region)?.map((region) => (
                                <option value={region}><FormattedMessage id="ENUM.REGION" values={{ region: region }} /></option>
                            ))}
                        </select>
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    {!!technicalWords && technicalWords.length > 0 && (
                        <div className='fv-row mb-7'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.KEYWORDS'})}</label>

                            <Select
                                name='technicalWords'
                                options={technicalWords.map(technicalWord => {
                                    return {
                                        value: technicalWord.id,
                                        label: technicalWord.name
                                    };})}
                                isMulti
                                onChange={(e) => (
                                    props.values.technicalWords = e.map((elt) => elt.value!)
                                )}
                                defaultValue={technicalAdvisor?.technicalWords?.map(technicalWord => {
                                    return {
                                        value: technicalWord,
                                        label: technicalWords.find(tw => tw.id == technicalWord)?.name
                                    };})}
                            />
                            {/* end::Label */}
                        </div>
                    )}
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    {(technicalAdvisor && !technicalAdvisor.blocked || !technicalAdvisor) && (
                        <div className='fv-row mb-7'>
                            {/* begin::Label */}
                            <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.ACTIVE'})}</label>
                            <div className='form-check form-check-solid form-switch fv-row'>
                                <input
                                    {...props.getFieldProps('active')}
                                    className='form-check-input w-60px h-30px'
                                    type='checkbox'
                                    defaultChecked={props.values.active}
                                />
                            </div>
                            {/* end::Label */}
                        </div>

                    )}
                {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.FULLTIME_AVAILABLE'})}</label>
                        <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                                {...props.getFieldProps('fulltimeAvailable')}
                                className='form-check-input w-60px h-30px'
                                type='checkbox'
                                defaultChecked={props.values.fulltimeAvailable}
                            />
                        </div>
                        {/* end::Label */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.NB_HOURS_INTERVIEWS_CURRENT_YEAR'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('nbHoursInterviewsCurrentYear')}
                            type='text'
                            name='nbHoursInterviewsCurrentYear'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.nbHoursInterviewsCurrentYear && props.errors.nbHoursInterviewsCurrentYear},
                                {
                                    'is-valid': props.touched.nbHoursInterviewsCurrentYear && !props.errors.nbHoursInterviewsCurrentYear,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.nbHoursInterviewsCurrentYear && props.errors.nbHoursInterviewsCurrentYear && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.nbHoursInterviewsCurrentYear}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}



                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={props.isSubmitting || !props.isValid || !props.touched}
                    >
                        <span className='indicator-label'>{intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.ACTION.SAVE'})}</span>
                        {(props.isSubmitting) && (
                            <span className='indicator-progress'>
                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            )}
            </Formik>



        </>
    )
}

export {MainInfos}
