import React, {FC, useRef, useState} from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
    LineOfBusinessDto, TechnicalWordDto, TechnicalWordService,
} from "../../../../../services/requests";
import {useIntl} from "react-intl";
import Select, {SelectInstance} from "react-select";
import CreatableSelect from "react-select/creatable";

type Props = {
    lineOfBusiness: LineOfBusinessDto,
    technicalWords?: TechnicalWordDto[],
    refreshTechnicalWords: Function
}

type Option= {
    label: string
    value: string
}

const TechnicalWords: FC<Props> = ({technicalWords, lineOfBusiness, refreshTechnicalWords}) => {

    const intl = useIntl()
    const ref = useRef<SelectInstance>(null);

    const [currentTechnicalWord, setCurrentTechnicalWord] = useState<Option>();


    const handleCreate = (inputValue: string) => {
        confirmAlert({
            title: intl.formatMessage({id: 'FORM.TECHNICAL_WORD_EDIT.CONFIRM_ADD_JOB_POSITIONS_TITLE'}),
            message: intl.formatMessage({id: 'FORM.TECHNICAL_WORD_EDIT.CONFIRM_ADD_JOB_POSITIONS_MESSAGE'}, {technicalWord: inputValue}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'COMMON.YES'}),
                    onClick: () => processCreation(inputValue)
                },
                {
                    label: intl.formatMessage({id: 'COMMON.NO'})
                }
            ]
        });
    }

    const updateElement = async () => {
        if(currentTechnicalWord?.value) {
            const originalTechnicalWord: TechnicalWordDto | undefined = technicalWords?.find(e => e.id == currentTechnicalWord?.value)
            await TechnicalWordService.update(currentTechnicalWord?.value,
                {
                    name: currentTechnicalWord.label,
                    lineOfBusiness: lineOfBusiness.id,
                    id: currentTechnicalWord.value
                })
            refreshTechnicalWords()
            setCurrentTechnicalWord(undefined);
            const selectEl = ref.current;
            if(selectEl) {
                selectEl.clearValue();
            }
        }
    }

    const processCreation = async (name: string) => {
        await TechnicalWordService.create({name: name, lineOfBusiness: lineOfBusiness.id})
        refreshTechnicalWords()
    }

    return (
        <div className='w-100'>
            {/* begin::Input group */}
                <div className='row mb-7 w-100'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.TECHNICAL_WORD_EDIT.EXISTING_TECHNICAL_WORDS'})}</label>

                    <CreatableSelect
                        ref={ref}
                        name='technicalWords'
                        isClearable
                        options={technicalWords?.map(technicalWord => {
                            return {
                                value: technicalWord.id,
                                label: technicalWord.name
                            } as Option;})}
                        onCreateOption={handleCreate}
                        onChange={(e) => setCurrentTechnicalWord(e as Option)}
                    />
                    {/* end::Label */}
                </div>
            {/* end::Input group */}

            {currentTechnicalWord?.value && (
                <div className='row mb-7 w-100'>
                    <input
                        value={currentTechnicalWord.label}
                        type='text'
                        name='name'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        onChange={e => setCurrentTechnicalWord({label: e.target.value, value: currentTechnicalWord?.value})}
                    />
                    <div className='text-center pt-15'>
                        <button
                            className='btn btn-success me-3'
                            onClick={updateElement}
                        >
                            <i className="bi bi-pencil-square"></i>{intl.formatMessage({id: 'COMMON.UPDATE'})}
                        </button>

                        {/*<button*/}
                        {/*    className='btn btn-danger'*/}
                        {/*    onClick={deleteElement}*/}
                        {/*>*/}
                        {/*    <i className="bi bi-trash"></i>Delete*/}
                        {/*</button>*/}
                    </div>
                </div>

            )}


        </div>
    )
}

export {TechnicalWords}
