import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Field, Formik, FormikValues, useFormik} from 'formik'
import {ID, isNotEmpty} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {
    InterviewContentQuestionCommand,
    InterviewContentQuestionDto, InterviewContentQuestionService,
} from "../../../../../services/requests";
import {useIntl} from "react-intl";
import questionType = InterviewContentQuestionCommand.questionType;
import {useNavigate} from "react-router-dom";
import Markdown from "react-markdown";

type Props = {
    question?: InterviewContentQuestionDto,
    subject: string,
    lineOfBusiness: string,
    refreshQuestionId: Function,
    refreshQuestion: Function
}

const editThematicSchema = Yup.object().shape({
    shortnameFr: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols')
        .required('French short name is required'),
    shortnameEn: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols')
        .required('English short name is required'),
    questionFr: Yup.string()
        .min(10, 'Minimum 10 symbols')
        .required('French question is required'),
    questionEn: Yup.string()
        .min(10, 'Minimum 10 symbols')
        .required('English question is required'),
    answerFr: Yup.string()
        .min(10, 'Minimum 10 symbols')
        .required('French answer is required'),
    answerEn: Yup.string()
        .min(10, 'Minimum 10 symbols')
        .required('English answer is required'),
})
const MainInfos: FC<Props> = ({question, refreshQuestionId, refreshQuestion, subject, lineOfBusiness}) => {

    const intl = useIntl()
    const navigate = useNavigate()




    const [questionForEdit] = useState<InterviewContentQuestionCommand>({
        ...question,
        answerEn: question?.answerEn as string,
        answerFr: question?.answerFr as string,
        questionEn: question?.questionEn as string,
        questionFr: question?.questionFr as string,
        shortnameEn: question?.shortnameEn as string,
        shortnameFr: question?.shortnameFr as string,
        active: question?.active as boolean,
        questionType: question?.questionType as questionType,
        lineOfBusiness: lineOfBusiness,
        interviewContentSubject: subject
    })

    const submitForm = async (values, {setSubmitting}) => {

            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await InterviewContentQuestionService.update9(values.id as string, values);
                    refreshQuestion();
                } else {
                    const res = await InterviewContentQuestionService.create10(values)
                    refreshQuestionId(res.id)

                }
            } catch (ex) {
                console.error(ex)
            } finally {
                navigate('/admin/interviews-content/questions', {state : {subjectId: subject}});
            }
    }

    return (
        <>
            <Formik
                initialValues={questionForEdit} validationSchema={editThematicSchema} onSubmit={submitForm}
                >
                {props => (
            <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <input
                    {...props.getFieldProps('lineOfBusiness')}
                    type="hidden"
                    name="lineOfBusiness"
                />
                <input
                    {...props.getFieldProps('interviewContentSubject')}
                    type="hidden"
                    name="interviewContentSubject"
                />
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                >

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.SHORTNAME_FR'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('shortnameFr')}
                            type='text'
                            name='shortnameFr'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.shortnameFr && props.errors.shortnameFr},
                                {
                                    'is-valid': props.touched.shortnameFr && !props.errors.shortnameFr,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.shortnameFr && props.errors.shortnameFr && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.shortnameFr}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.SHORTNAME_EN'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('shortnameEn')}
                            type='text'
                            name='shortnameEn'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.shortnameEn && props.errors.shortnameEn},
                                {
                                    'is-valid': props.touched.shortnameEn && !props.errors.shortnameEn,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.shortnameEn && props.errors.shortnameEn && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.shortnameEn}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.ACTIVE'})}</label>
                        <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                                {...props.getFieldProps('active')}
                                className='form-check-input w-60px h-30px'
                                type='checkbox'
                                defaultChecked={props.values.active}
                            />
                        </div>
                        {/* end::Label */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.QUESTION_TYPE'})}</label>
                        <div className='form-check form-check-solid form-switch fv-row'>
                            {Object.keys(questionType)?.map((questionType) => (
                                <label className='form-check form-check-inline form-check-solid me-5'>
                                    <input
                                        {...props.getFieldProps('questionType')}
                                        className='form-check-input'
                                        name='questionType'
                                        type='radio'
                                        value={questionType}
                                    />
                                    <span className='fw-bold ps-2 fs-6'>{questionType}</span>
                                </label>
                            ))}
                        </div>
                        {/* end::Label */}
                    </div>
                    {/* end::Input group */}



                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.QUESTION_FR'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <Field
                            {...props.getFieldProps('questionFr')}
                            component='textarea'
                            name='questionFr'
                            rows={10}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.questionFr && props.errors.questionFr},
                                {
                                    'is-valid': props.touched.questionFr && !props.errors.questionFr,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.questionFr && props.errors.questionFr && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.questionFr}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    <Markdown>{props.values.questionFr}</Markdown>
                    <hr />
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.QUESTION_EN'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <Field
                            {...props.getFieldProps('questionEn')}
                            component='textarea'
                            rows={10}
                            name='questionEn'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.questionEn && props.errors.questionEn},
                                {
                                    'is-valid': props.touched.questionEn && !props.errors.questionEn,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.questionEn && props.errors.questionEn && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.questionEn}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    <Markdown>{props.values.questionEn}</Markdown>
                    <hr />

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.ANSWER_FR'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <Field
                            {...props.getFieldProps('answerFr')}
                            component='textarea'
                            rows={10}
                            name='answerFr'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.answerFr && props.errors.answerFr},
                                {
                                    'is-valid': props.touched.answerFr && !props.errors.answerFr,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.answerFr && props.errors.answerFr && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.answerFr}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    <Markdown>{props.values.answerFr}</Markdown>
                    <hr />

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.ANSWER_EN'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <Field
                            {...props.getFieldProps('answerEn')}
                            component='textarea'
                            rows={10}
                            name='answerEn'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.answerEn && props.errors.answerEn},
                                {
                                    'is-valid': props.touched.answerEn && !props.errors.answerEn,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.answerEn && props.errors.answerEn && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.answerEn}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    <Markdown>{props.values.answerEn}</Markdown>
                    <hr />
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={props.isSubmitting || !props.isValid || !props.touched}
                    >
                        <span className='indicator-label'>{intl.formatMessage({id: 'FORM.QUESTION_EDIT.ACTION.SAVE'})}</span>
                        {(props.isSubmitting) && (
                            <span className='indicator-progress'>
                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            )}
            </Formik>



        </>
    )
}

export {MainInfos}
