/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetInterviewContentQuestions } from '../models/GetInterviewContentQuestions';
import type { InterviewContentQuestionDetailDto } from '../models/InterviewContentQuestionDetailDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BusinessLogicInterviewContentQuestionsService {

    /**
     * @param requestBody 
     * @returns InterviewContentQuestionDetailDto OK
     * @throws ApiError
     */
    public static getInterviewContentQuestions(
requestBody: GetInterviewContentQuestions,
): CancelablePromise<Array<InterviewContentQuestionDetailDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/business-logic/interview-content-questions/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
