/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InterviewContentQuestionCommand = {
    id?: string;
    lineOfBusiness: string;
    interviewContentSubject: string;
    questionType: InterviewContentQuestionCommand.questionType;
    active?: boolean;
    shortnameFr: string;
    shortnameEn: string;
    questionFr: string;
    questionEn: string;
    answerFr: string;
    answerEn: string;
};

export namespace InterviewContentQuestionCommand {

    export enum questionType {
        THEORICAL = 'THEORICAL',
        PRACTICAL = 'PRACTICAL',
    }


}
