/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TechnicalAdvisorChangeBlockedStatusCommand } from '../models/TechnicalAdvisorChangeBlockedStatusCommand';
import type { TechnicalAdvisorCommand } from '../models/TechnicalAdvisorCommand';
import type { TechnicalAdvisorDto } from '../models/TechnicalAdvisorDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TechnicalAdvisorService {

    /**
     * @param id 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static findById1(
id: string,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisors/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static update1(
id: string,
requestBody: TechnicalAdvisorCommand,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/technical-advisors/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static changeBlockedStatus(
requestBody: TechnicalAdvisorChangeBlockedStatusCommand,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/technical-advisors/changeBlockedStatus',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TechnicalAdvisorDto Created
     * @throws ApiError
     */
    public static create1(
requestBody: TechnicalAdvisorCommand,
): CancelablePromise<TechnicalAdvisorDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/technical-advisors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param lineOfBusiness 
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static findAllByLineOfBusiness1(
lineOfBusiness: string,
): CancelablePromise<Array<TechnicalAdvisorDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisors/byLineOfBusiness',
            query: {
                'lineOfBusiness': lineOfBusiness,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns TechnicalAdvisorDto OK
     * @throws ApiError
     */
    public static findAll1(): CancelablePromise<Array<TechnicalAdvisorDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisors/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
