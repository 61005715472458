import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {TechnicalAdvisorsListWrapper} from './technical-advisors-list/TechnicalAdvisorsListPage'
import React from "react";
import {useIntl} from "react-intl";
import {Horizontal} from "../../modules/wizards/components/Horizontal";
import {TechnicalAdvisorEditPage} from "./technical-advisor-edit/TechnicalAdvisorEditPage";


const TechnicalAdvisorsRoutesDefinition = () => {
    const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PAGE.TECHNICAL_ADVISORS.TITLE'})}</PageTitle>
              <TechnicalAdvisorsListWrapper />
            </>
          }
        />
      </Route>
        <Route
            path='edit'
            element={
                <>
                    <TechnicalAdvisorEditPage />
                </>
            }
        />
      <Route index element={<Navigate to='/admin/technical-advisors/list' />} />
    </Routes>
  )
}

export default TechnicalAdvisorsRoutesDefinition
