import {FormattedMessage, useIntl} from "react-intl";
import React, {FC} from "react";
import {SearchFormValues} from "./SearchForm";
import {useQuery} from "react-query";
import {
    BusinessLogicInterviewTechnicalAdvisorService,
    GetTechnicalAdvisorsForInterview, TechnicalWordDto
} from "../../../../services/requests";
import {Link} from "react-router-dom";

type Props = {
    searchValues: SearchFormValues,
    technicalWords?: TechnicalWordDto[]
}

export const SearchResults: FC<Props> = ({searchValues, technicalWords}) => {
    const intl = useIntl()

    const { data: technicalAdvisorsEligibles } = useQuery({
        queryKey: ['technicalAdvisorsEligibles', searchValues],
        queryFn: () => {
            // Do something here

            return BusinessLogicInterviewTechnicalAdvisorService.getTechnicalAdvisorsForInterview(searchValues as GetTechnicalAdvisorsForInterview);
        }
    });

    function resolveTechnicalWord(technicalWordId: string): string {
        let technicalWordFound = technicalWords?.find((element) => {
            return element.id == technicalWordId;
        })
        return technicalWordFound?.name ? technicalWordFound.name : "technicalWordId";

    }

    function isTechnicalWordInList(technicalWordId: string, referenceList?: string[]): boolean {
        let res = referenceList?.find(element => {
            return element == technicalWordId
        })
        return !!res;
    }

    function getBackgroundForScore(score?: number): string {
        let classInfo = 'progress-bar bg-'
        if(!score || score < 50) {
            classInfo += 'danger'
        } else if(score < 75) {
            classInfo += 'warning'
        } else {
            classInfo += 'success'
        }
        return classInfo
    }
    return (
        <>
            <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-800 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bold text-muted'>
                        <th className='min-w-220px'>{intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS_ELIGIBLES.HEADER.INFORMATIONS'})}</th>
                        <th className='min-w-130px text-center'>{intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS_ELIGIBLES.HEADER.NB_HOURS_DONE'})}</th>
                        <th className='min-w-200px text-center'>{intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS_ELIGIBLES.HEADER.TECHNICAL_WORDS'})}</th>
                        <th className='max-w-150px min-w-100px text-center'>{intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS_ELIGIBLES.HEADER.RATING'})}</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    {technicalAdvisorsEligibles?.map((technicalAdvisor) => (
                        <tr>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-truncate'  title={technicalAdvisor.name}>
                                            <i className="bi bi-person mx-1"></i>{technicalAdvisor.name}
                                        </a>
                                        <a href='#' className='d-block fs-6 text-truncate' style={{width: '220px'}} title={technicalAdvisor.email}>
                                            <i className="bi bi-envelope mx-1"></i>
                                            <Link to="#" onClick={() => navigator.clipboard.writeText(technicalAdvisor.email)} key={technicalAdvisor.email}>
                                                {technicalAdvisor.email}
                                            </Link>
                                        </a>
                                        <a href='#' className='d-block fs-6'>
                                            <i className="bi bi-globe-americas mx-1"></i><FormattedMessage id="ENUM.REGION" values={{ region: technicalAdvisor.region }} />
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">
                                        { technicalAdvisor.warningNbHoursInterviews ? (
                                            <span className="text-warning fw-bold"><i className="bi bi-exclamation-triangle text-warning mx-2" title={intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS_ELIGIBLES.INFO.NB_HOURS_TOO_HIGH'})}></i> {technicalAdvisor.nbHoursInterviewsCurrentYear}</span>
                                        ) : (
                                            <span className="fw-bold text-success">
                                                { technicalAdvisor.fulltimeAvailable && (
                                                    <i className="bi bi-brightness-high-fill text-success mx-2" title={intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS_ELIGIBLES.INFO.FULLTIME_AVAILABLE'})}></i>
                                                )}
                                                {technicalAdvisor.nbHoursInterviewsCurrentYear}</span>
                                        )}
                            </td>
                            <td className="text-center">
                                {technicalAdvisor.technicalWords?.map((technicalWordId) => (

                                    <span
                                        className={isTechnicalWordInList(technicalWordId, technicalAdvisor.matchingTechnicalWords)?'badge badge-success mx-1':'badge badge-light mx-1'}
                                    >
                                  {resolveTechnicalWord(technicalWordId)}
                                </span>
                                ))}
                            </td>
                            <td className="text-center">
                                <div className='d-flex flex-column w-100 me-2'>
                                    <div className='d-flex flex-stack mb-2'>
                                        <span className='text-muted me-2 fs-7 fw-semibold'>{technicalAdvisor.correspondingScore}%</span>
                                    </div>
                                    <div className='progress h-6px w-100'>
                                        <div
                                            className={getBackgroundForScore(technicalAdvisor.correspondingScore)}
                                            role='progressbar'
                                            style={{width: technicalAdvisor.correspondingScore + '%'}}
                                        ></div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    {/* end::Table body */}
                </table>
                {/* end::Table */}
            </div>
        </>
    )
}

