/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InterviewDetailDto = {
    id: string;
    createdAt?: string;
    updatedAt?: string;
    recruiterName?: string;
    applicantName?: string;
    additionalInformations?: string;
    levelOfExpertise?: InterviewDetailDto.levelOfExpertise;
    urgent?: boolean;
    jobPosition?: string;
    jobPositionName?: string;
    technicalAdvisorDesignated?: string;
    technicalAdvisorDesignatedName?: string;
};

export namespace InterviewDetailDto {

    export enum levelOfExpertise {
        A = 'A',
        B = 'B',
        C = 'C',
        D = 'D',
        E = 'E',
    }


}
