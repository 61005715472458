/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InterviewContentQuestionDetailDto = {
    id: string;
    createdAt?: string;
    updatedAt?: string;
    lineOfBusiness?: string;
    jobPosition?: string;
    levelsOfExpertise?: Array<'A' | 'B' | 'C' | 'D' | 'E'>;
    interviewContentThematicParentId?: string;
    interviewContentThematicParentNameFr?: string;
    interviewContentThematicParentNameEn?: string;
    interviewContentThematicId?: string;
    interviewContentThematicNameFr?: string;
    interviewContentThematicNameEn?: string;
    interviewContentSubjectId?: string;
    interviewContentSubjectNameFr?: string;
    interviewContentSubjectNameEn?: string;
    interviewContentQuestionId?: string;
    interviewContentQuestionNameFr?: string;
    interviewContentQuestionNameEn?: string;
    interviewContentQuestionQuestionFr?: string;
    interviewContentQuestionQuestionEn?: string;
    interviewContentQuestionAnswerFr?: string;
    interviewContentQuestionAnswerEn?: string;
    interviewContentQuestionActive?: boolean;
    interviewContentQuestionQuestionType?: InterviewContentQuestionDetailDto.interviewContentQuestionQuestionType;
};

export namespace InterviewContentQuestionDetailDto {

    export enum interviewContentQuestionQuestionType {
        THEORICAL = 'THEORICAL',
        PRACTICAL = 'PRACTICAL',
    }


}
