import React, {FC} from "react";
import {useIntl} from "react-intl";
import {useQuery} from "react-query";
import {
    InterviewContentQuestionService,
    InterviewContentSubjectService
} from "../../../../services/requests";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../../modules/auth";
import {ID, KTIcon} from "../../../../_metronic/helpers";
import {useLocation} from "react-router";

type Params = {
    subjectId: ID
}

const QuestionsListPage: FC = () => {
    const location = useLocation();
    const locationState = location?.state as Params;
    const intl = useIntl()
    const navigate = useNavigate()
    const {currentUser} = useAuth()
    console.log(locationState.subjectId)
    const { data: subject } = useQuery({
        queryKey: ['subject',locationState.subjectId],
        queryFn: () => InterviewContentSubjectService.findById10(locationState.subjectId as string),
    })

    const { data: questions } = useQuery({
        queryKey: ['questions',locationState.subjectId],
        queryFn: () => InterviewContentQuestionService.findAllBySubject(locationState.subjectId as string),
    })

    const goToAdd = () => {
        navigate('/admin/interviews-content/questions/edit', {state : {lineOfBusiness: subject?.lineOfBusiness, subject: locationState.subjectId}});
    }


    return (
     <>
         {/* begin::Row */}
         {questions && (
             <div className='g-5 gx-xxl-8 mt-5'>
                 <div className={'card'}>
                     {/* begin::Header */}
                     <div className='card-header border-0 pt-5'>
                         <h3 className='card-title align-items-start flex-column'>
                             <span className='card-label fw-bold fs-3 mb-1 text-primary'>{intl.formatMessage({id: 'TABLE.QUESTIONS.TITLE'})}</span>
                         </h3>
                         <button type='button' className='btn btn-primary' onClick={goToAdd}>
                             <KTIcon iconName='plus' className='fs-2' />
                             {intl.formatMessage({id: 'TABLE.QUESTIONS.NEW_QUESTION'})}
                         </button>
                     </div>
                     {/* end::Header */}
                     {/* begin::Body */}
                     <div className='card-body py-3'>
                         {/* begin::Table container */}
                         <div className='table-responsive'>
                             {/* begin::Table */}
                             <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                 {/* begin::Table head */}
                                 <thead>
                                 <tr className='fw-bold text-muted'>
                                     <th className='min-w-325px'>{intl.formatMessage({id: 'TABLE.QUESTIONS.HEADER.SHORTNAME_FR'})}</th>
                                     <th className='min-w-325px'>{intl.formatMessage({id: 'TABLE.QUESTIONS.HEADER.SHORTNAME_EN'})}</th>
                                 </tr>
                                 </thead>
                                 {/* end::Table head */}
                                 {/* begin::Table body */}
                                 <tbody>
                                 {questions?.map((question) => (
                                     <tr key={question.id}>
                                         <td>
                                             <div className='d-flex align-items-center'>
                                                 <div className='d-flex justify-content-start flex-column'>
                                                        <Link to='/admin/interviews-content/questions/edit' className='text-dark fw-bold text-hover-primary fs-6' state={{lineOfBusiness: question.lineOfBusiness, subject: question.interviewContentSubject, id: question.id}} >{question.shortnameFr}</Link>
                                                 </div>
                                             </div>
                                         </td>
                                         <td>
                                             <div className='d-flex align-items-center'>
                                                 <div className='d-flex justify-content-start flex-column'>
                                                     <Link to='/admin/interviews-content/questions/edit' className='text-dark fw-bold text-hover-primary fs-6' state={{lineOfBusiness: question.lineOfBusiness, subject: question.interviewContentSubject, id: question.id}} >{question.shortnameEn}</Link>
                                                 </div>
                                             </div>
                                         </td>
                                     </tr>
                                 ))}
                                 </tbody>
                                 {/* end::Table body */}
                             </table>
                             {/* end::Table */}
                         </div>
                         {/* end::Table container */}
                     </div>
                     {/* begin::Body */}
                 </div>
             </div>
         )}
         {/* end::Row */}
     </>
    )
}

export {QuestionsListPage};