import {KTIcon} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useIntl} from "react-intl";
import {useQueryRequest} from "../../core/QueryRequestProvider";
import {useNavigate} from "react-router-dom";

const TechnicalAdvisorsListToolbar = () => {
    const intl = useIntl();
    const {state} = useQueryRequest();
    const navigate = useNavigate();

  const goToAdd = () => {
        navigate('/admin/technical-advisors/edit', {state: {
                lineOfBusinessId: state.lineOfBusiness
            }});
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* begin::Add user */}
        {!!state.lineOfBusiness && (
            <button type='button' className='btn btn-primary' onClick={goToAdd}>
            <KTIcon iconName='plus' className='fs-2' />
            {intl.formatMessage({id: 'TABLE.TECHNICAL_ADVISORS.NEW_TECHNICAL_ADVISOR'})}
            </button>
        )}
        {/* end::Add user */}

    </div>
  )
}

export {TechnicalAdvisorsListToolbar}
