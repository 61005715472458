/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetInterviewContentQuestions = {
    lineOfBusiness: string;
    jobPosition: string;
    levelOfExpertise: GetInterviewContentQuestions.levelOfExpertise;
    lang: GetInterviewContentQuestions.lang;
};

export namespace GetInterviewContentQuestions {

    export enum levelOfExpertise {
        A = 'A',
        B = 'B',
        C = 'C',
        D = 'D',
        E = 'E',
    }

    export enum lang {
        FR = 'FR',
        EN = 'EN',
    }


}
