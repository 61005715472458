import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {Formik, FormikValues, useFormik} from 'formik'
import {ID, isNotEmpty} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {
    InterviewContentSubjectCommand,
    InterviewContentSubjectDto, InterviewContentSubjectService,
    InterviewContentThematicCommand,
    InterviewContentThematicDto, InterviewContentThematicService,
    LineOfBusinessCommand,
    LineOfBusinessDto, LineOfBusinessService,
} from "../../../../../services/requests";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

type Props = {
    subject?: InterviewContentSubjectDto,
    thematic: string,
    lineOfBusiness: string,
    refreshSubjectId: Function,
    refreshSubject: Function
}

const editThematicSchema = Yup.object().shape({
    nameFr: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols')
        .required('French name is required'),
    nameEn: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 50 symbols')
        .required('English name is required'),
})
const MainInfos: FC<Props> = ({subject, refreshSubjectId, refreshSubject, thematic, lineOfBusiness}) => {

    const intl = useIntl()
    const navigate = useNavigate()




    const [subjectForEdit] = useState<InterviewContentSubjectCommand>({
        ...subject,
        nameFr: subject?.nameFr as string,
        nameEn: subject?.nameEn as string,
        lineOfBusiness: lineOfBusiness,
        interviewContentThematic: thematic,
    })

    const submitForm = async (values, {setSubmitting}) => {

            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await InterviewContentSubjectService.update8(values.id as string, values);
                    refreshSubject();
                } else {
                    const res = await InterviewContentSubjectService.create9(values)
                    refreshSubjectId(res.id)

                }
            } catch (ex) {
                console.error(ex)
            } finally {
                navigate('/admin/interviews-content/subjects', {state : {thematicId: thematic}});
            }
    }

    return (
        <>
            <Formik
                initialValues={subjectForEdit} validationSchema={editThematicSchema} onSubmit={submitForm}
                >
                {props => (
            <form className='w-100' onSubmit={props.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <input
                    {...props.getFieldProps('lineOfBusiness')}
                    type="hidden"
                    name="lineOfBusiness"
                />
                <input
                    {...props.getFieldProps('interviewContentThematic')}
                    type="hidden"
                    name="interviewContentThematic"
                />
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                >

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.SUBJECT_EDIT.NAME_FR'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('nameFr')}
                            type='text'
                            name='nameFr'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.nameFr && props.errors.nameFr},
                                {
                                    'is-valid': props.touched.nameFr && !props.errors.nameFr,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.nameFr && props.errors.nameFr && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.nameFr}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.SUBJECT_EDIT.NAME_EN'})}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...props.getFieldProps('nameEn')}
                            type='text'
                            name='nameEn'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': props.touched.nameEn && props.errors.nameEn},
                                {
                                    'is-valid': props.touched.nameEn && !props.errors.nameEn,
                                }
                            )}
                            autoComplete='off'
                            disabled={props.isSubmitting}
                        />
                        {props.touched.nameEn && props.errors.nameEn && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{props.errors.nameEn}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}


                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={props.isSubmitting || !props.isValid || !props.touched}
                    >
                        <span className='indicator-label'>{intl.formatMessage({id: 'FORM.SUBJECT_EDIT.ACTION.SAVE'})}</span>
                        {(props.isSubmitting) && (
                            <span className='indicator-progress'>
                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            )}
            </Formik>



        </>
    )
}

export {MainInfos}
