import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery} from "react-query";
import {
    InterviewContentThematicService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {PageTitle} from "../../../../_metronic/layout/core";

type Params = {
    id?: ID
    lineOfBusiness: string
}

const ThematicEditPage: FC = () => {
    const location = useLocation();
    const locationState = location?.state as Params;
    const intl = useIntl()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

    const [thematicId, setThematicId] = useState(locationState?.id);
    const [lineOfBusiness, setLineOfBusiness] = useState(locationState?.lineOfBusiness);


    const {data: thematic, isLoading: isLoading, refetch: refetchThematic} = useQuery({
            queryKey: ['thematic', thematicId],
            queryFn: () => {
                return InterviewContentThematicService.findById9(thematicId as string);
            },
            enabled: !!thematicId
        }
    )

    const everythingLoaded = !isLoading;


    const pageTitle = thematic ?
        intl.formatMessage({id: 'PAGE.THEMATIC_EDIT.TITLE.UPDATE'}, {name: thematic?.nameFr})
        : intl.formatMessage({id: 'PAGE.THEMATIC_EDIT.TITLE.NEW'});

    const refreshThematicId = (newId: string) => {
        setThematicId(newId);
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {pageTitle}
            </PageTitle>
            <div className='card mb-5'>
                <div className='card-body'>
                    {everythingLoaded && (
                        <div className='mx-auto mw-600px w-100 pt-5'>
                            <MainInfos lineOfBusiness={lineOfBusiness} refreshThematic={refetchThematic} refreshThematicId={refreshThematicId} thematic={thematic} />
                        </div>
                    )}
                </div>
            </div>
        </>

    )
}

export {ThematicEditPage}
