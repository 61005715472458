import {FormattedMessage, useIntl} from "react-intl";
import React, {FC, useEffect, useState} from "react";
import {InterviewContentQuestionsSearchFormValues} from "./SearchForm";
import {useQuery} from "react-query";
import {
    BusinessLogicInterviewContentQuestionsService,
    BusinessLogicInterviewTechnicalAdvisorService,
    GetInterviewContentQuestions,
    GetTechnicalAdvisorsForInterview, InterviewContentQuestionDetailDto,
    InterviewContentQuestionDto,
    InterviewContentQuestionSPublicService,
    TechnicalWordDto
} from "../../../../services/requests";
import {Link} from "react-router-dom";
import {Languages} from "../../../../_metronic/partials/layout/header-menus/Languages";
import {KTSVG} from "../../../../_metronic/helpers";
import Markdown from "react-markdown";

type Props = {
    searchValues: InterviewContentQuestionsSearchFormValues
}


export const SearchResults: FC<Props> = ({searchValues}) => {
    const intl = useIntl()

    const { data: interviewContentQuestions } = useQuery({
        queryKey: ['interviewContentQuestions', searchValues],
        queryFn: () => {
            // Do something here

            return BusinessLogicInterviewContentQuestionsService.getInterviewContentQuestions(searchValues as GetInterviewContentQuestions);
        }
    });

    const [interviewContentQuestionsUsed, setInterviewContentQuestionsUsed] = useState<Map<string,Map<string, InterviewContentQuestionDetailDto[]>>>({} as Map<string,Map<string, InterviewContentQuestionDetailDto[]>>);

    const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});

    const getFullThematic = (input: InterviewContentQuestionDetailDto) => {
      let thematicNameFr = "";
      let thematicNameEn = "";

      if(input.interviewContentThematicParentId != undefined) {
       thematicNameFr = input.interviewContentThematicParentNameFr as string + " / ";
       thematicNameEn = input.interviewContentThematicParentNameEn as string + " / ";
      }
      thematicNameFr += input.interviewContentThematicNameFr as string;
      thematicNameEn += input.interviewContentThematicNameEn as string;
      if(searchValues.lang.toLowerCase() == "fr") {
        return thematicNameFr
      }
      return thematicNameEn;
    };

    const getSubject = (input: InterviewContentQuestionDetailDto) => {
        if(searchValues.lang.toLowerCase() == "fr") {
            return input.interviewContentSubjectNameFr;
        }
        return input.interviewContentSubjectNameEn;
    };

    const getQuestionName = (input: InterviewContentQuestionDetailDto) => {
        if(searchValues.lang.toLowerCase() == "fr") {
            return input.interviewContentQuestionNameFr;
        }
        return input.interviewContentQuestionNameEn;
    };
    const getQuestion = (input: InterviewContentQuestionDetailDto) => {
        if(searchValues.lang.toLowerCase() == "fr") {
            return input.interviewContentQuestionQuestionFr;
        }
        return input.interviewContentQuestionQuestionEn;
    };

    const getAnswer = (input: InterviewContentQuestionDetailDto) => {
        if(searchValues.lang.toLowerCase() == "fr") {
            return input.interviewContentQuestionAnswerFr;
        }
        return input.interviewContentQuestionAnswerEn;
    };

    const groupBySubject = (x:InterviewContentQuestionDetailDto[]) => groupBy(x, i => getSubject(i));

    useEffect(() => {
        if (interviewContentQuestions) {
            let listTemp : Map<string, Map<string, InterviewContentQuestionDetailDto[]>> = {} as Map<string,Map<string, InterviewContentQuestionDetailDto[]>>;
            Object.entries(groupBy(interviewContentQuestions, i => getFullThematic(i))).map(([thematic, list]) => {
                listTemp[thematic] = groupBySubject(list as InterviewContentQuestionDetailDto[]);
            });
            setInterviewContentQuestionsUsed(listTemp);

        }
    }, [interviewContentQuestions]);


    return (
        <>
            <div className='accordion' id='kt_accordion_thematics'>
                {interviewContentQuestionsUsed && Object.keys(interviewContentQuestionsUsed).sort().map((thematic, index) => (
                    <div className='accordion-item'>
                    <h2 className='accordion-header' id={"kt_accordion_thematics_header_" + index}>
                        <button
                            className='accordion-button fs-4 fw-bold collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target={"#kt_accordion_thematics_body_" + index}
                            aria-expanded='false'
                            aria-controls={'kt_accordion_thematics_body_' + index}
                        >
                            {thematic}
                        </button>
                    </h2>
                    <div
                        id={'kt_accordion_thematics_body_'+ index}
                        className='accordion-collapse collapse'
                        aria-labelledby={"kt_accordion_thematics_header_" + index}
                        data-bs-parent='#kt_accordion_thematics'
                    >
                        <div className='accordion-body'>
                            <div className='accordion' id={"kt_accordion_subjects_" + index}>
                                {Object.keys(interviewContentQuestionsUsed[thematic]).map((subject, subjectIndex) => (
                                    <div className='accordion-item'>
                                        <h2 className='accordion-header' id={"kt_accordion_subjects_"+ index + "_header_"+ subjectIndex}>
                                            <button
                                                className='accordion-button fs-4 fw-bold collapsed'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target={'#kt_accordion_subjects_' + index + '_body_' + subjectIndex}
                                                aria-expanded='false'
                                                aria-controls={'kt_accordion_subjects_' + index + '_body_' + subjectIndex}
                                            >
                                                {subject}
                                            </button>
                                        </h2>
                                        <div
                                            id={'kt_accordion_subjects_' + index + '_body_' + subjectIndex}
                                            className='accordion-collapse collapse'
                                            aria-labelledby={'kt_accordion_subjects_' + index + '_header_' + subjectIndex}
                                            data-bs-parent={'#kt_accordion_subjects_' + index}
                                        >
                                            <div className='accordion-body'>
                                                       <div className='accordion accordion-icon-toggle' id={"kt_accordion_questions_" + index + "_" + subjectIndex}>
                                                           {interviewContentQuestionsUsed[thematic][subject].map((questionDetail, questionIndex) => (
                                                               <div className='mb-5'>
                                                                   <div
                                                                       className='accordion-header py-3 d-flex collapsed'
                                                                       id={"kt_accordion_questions_" + index + "_" + subjectIndex + "_header_"+ questionIndex}
                                                                           data-bs-toggle='collapse'
                                                                           data-bs-target={'#kt_accordion_questions_' + index + "_" + subjectIndex + '_body_' + questionIndex}
                                                                       >
                                                                       <span className="accordion-icon">
                                                                        <KTSVG
                                                                            className="svg-icon svg-icon-4"
                                                                            path="/media/icons/duotune/arrows/arr064.svg"
                                                                        />
                                                                      </span>
                                                                       <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                                                                        <span
                                                                            className={questionDetail.interviewContentQuestionQuestionType == InterviewContentQuestionDto.questionType.PRACTICAL ?'badge badge-danger mx-1':'badge badge-success mx-1'}
                                                                        >
                                                                            {questionDetail.interviewContentQuestionQuestionType}
                                                                        </span>
                                                                           {getQuestionName(questionDetail)}
                                                                       </h3>
                                                                   </div>
                                                                   <div
                                                                       id={'kt_accordion_questions_' + index + "_" + subjectIndex + '_body_' + questionIndex}
                                                                       className='collapse fs-6 ps-10'
                                                                       aria-labelledby={'kt_accordion_questions_' + index + "_" + subjectIndex + '_header_' + questionIndex}
                                                                       data-bs-parent={'#kt_accordion_questions_' + index + "_" + subjectIndex}
                                                                   >
                                                                       <span className="badge badge-info">{intl.formatMessage({id: 'PAGE.QUESTIONS_FOR_INTERVIEWS.QUESTION'})}</span>
                                                                       <Link to="#" onClick={() => navigator.clipboard.writeText(getQuestion(questionDetail) as string)}>
                                                                           <i className="bi bi-clipboard mx-2"></i>
                                                                       </Link>
                                                                       <Markdown>{getQuestion(questionDetail)}</Markdown>
                                                                       <hr />
                                                                       <span className="badge badge-success">{intl.formatMessage({id: 'PAGE.QUESTIONS_FOR_INTERVIEWS.EXPECTED_ANSWER'})}</span>
                                                                       <Link to="#" onClick={() => navigator.clipboard.writeText(getAnswer(questionDetail) as string)}>
                                                                           <i className="bi bi-clipboard mx-2"></i>
                                                                       </Link>
                                                                       <Markdown>{getAnswer(questionDetail)}</Markdown>
                                                                   </div>
                                                               </div>
                                                           ))}
                                                       </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </>
    )
}

