/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {TechnicalAdvisorDto} from "../../../../../../services/requests";

type Props = {
  bool: boolean
}

const TechnicalAdvisorBooleanCell: FC<Props> = ({bool}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>{bool?  (
          <i className='bi bi-check-square px-3 text-success'></i>
      )
      : (
          <i className='bi bi-x-square check px-3 text-danger'></i>
      )}</span>
    </div>
  </div>
)

export {TechnicalAdvisorBooleanCell}
