/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InterviewTechnicalWordDto } from './InterviewTechnicalWordDto';

export type InterviewDto = {
    id: string;
    createdAt?: string;
    updatedAt?: string;
    recruiterName?: string;
    recruiterEmail?: string;
    applicantName?: string;
    jobPosition?: string;
    technicalAdvisorDesignated?: string;
    levelOfExpertise?: InterviewDto.levelOfExpertise;
    lang?: InterviewDto.lang;
    additionalInformations?: string;
    currentStatus?: InterviewDto.currentStatus;
    technicalWords?: Array<InterviewTechnicalWordDto>;
    urgent?: boolean;
};

export namespace InterviewDto {

    export enum levelOfExpertise {
        A = 'A',
        B = 'B',
        C = 'C',
        D = 'D',
        E = 'E',
    }

    export enum lang {
        FR = 'FR',
        EN = 'EN',
    }

    export enum currentStatus {
        WAITING_ASSIGNMENT = 'WAITING_ASSIGNMENT',
        ASSIGNED = 'ASSIGNED',
        BLOCKED = 'BLOCKED',
        CANCELLED = 'CANCELLED',
        ARCHIVED = 'ARCHIVED',
    }


}
