import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import React from "react";
import {useIntl} from "react-intl";
import {LineOfBusinessesListPage} from "./line-of-businesses-list/LineOfBusinessesListPage";
import {LineOfBusinessEditPage} from "./line-of-business-edit/LineOfBusinessEditPage";


const TechnicalAdvisorsPagesDefinition = () => {
    const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESSES.TITLE'})}</PageTitle>
              <LineOfBusinessesListPage />
            </>
          }
        />
      </Route>
        <Route
            path='edit'
            element={
                <>
                    <LineOfBusinessEditPage />
                </>
            }
        />
      <Route index element={<Navigate to='/admin/line-of-businesses/list' />} />
    </Routes>
  )
}

export default TechnicalAdvisorsPagesDefinition
