import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {TechnicalAdvisorsListHeader} from './components/header/TechnicalAdvisorsListHeader'
import {TechnicalAdvisorsTable} from './table/TechnicalAdvisorsTable'
import {KTCard} from '../../../../_metronic/helpers'
import {useState} from "react";

const TechnicalAdvisorsListPage = () => {
  const {itemIdForUpdate} = useListView()
  const [currentLineOfBusiness, setCurrentLineOfBusiness] = useState('');



  return (
    <>
      <KTCard className='mt-5'>
        <TechnicalAdvisorsListHeader />
        <TechnicalAdvisorsTable />
      </KTCard>
    </>
  )
}

const TechnicalAdvisorsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TechnicalAdvisorsListPage />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TechnicalAdvisorsListWrapper}
