/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TechnicalAdvisorAcceptanceSkillCommand } from '../models/TechnicalAdvisorAcceptanceSkillCommand';
import type { TechnicalAdvisorAcceptanceSkillDto } from '../models/TechnicalAdvisorAcceptanceSkillDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TechnicalAdvisorSAcceptanceSkillService {

    /**
     * @param id 
     * @returns TechnicalAdvisorAcceptanceSkillDto OK
     * @throws ApiError
     */
    public static findById3(
id: string,
): CancelablePromise<TechnicalAdvisorAcceptanceSkillDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisor-acceptance-skills/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns TechnicalAdvisorAcceptanceSkillDto OK
     * @throws ApiError
     */
    public static update3(
id: string,
requestBody: TechnicalAdvisorAcceptanceSkillCommand,
): CancelablePromise<TechnicalAdvisorAcceptanceSkillDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/technical-advisor-acceptance-skills/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @returns TechnicalAdvisorAcceptanceSkillDto OK
     * @throws ApiError
     */
    public static delete(
id: string,
): CancelablePromise<TechnicalAdvisorAcceptanceSkillDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/technical-advisor-acceptance-skills/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TechnicalAdvisorAcceptanceSkillDto Created
     * @throws ApiError
     */
    public static create3(
requestBody: TechnicalAdvisorAcceptanceSkillCommand,
): CancelablePromise<TechnicalAdvisorAcceptanceSkillDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/technical-advisor-acceptance-skills',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param technicalAdvisor 
     * @returns TechnicalAdvisorAcceptanceSkillDto OK
     * @throws ApiError
     */
    public static findAllByTechnicalAdvisor(
technicalAdvisor: string,
): CancelablePromise<Array<TechnicalAdvisorAcceptanceSkillDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisor-acceptance-skills/byTechnicalAdvisor',
            query: {
                'technicalAdvisor': technicalAdvisor,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns TechnicalAdvisorAcceptanceSkillDto OK
     * @throws ApiError
     */
    public static findAll3(): CancelablePromise<Array<TechnicalAdvisorAcceptanceSkillDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-advisor-acceptance-skills/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
