/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InterviewContentSubjectCommand } from '../models/InterviewContentSubjectCommand';
import type { InterviewContentSubjectDto } from '../models/InterviewContentSubjectDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InterviewContentSubjectService {

    /**
     * @param id 
     * @returns InterviewContentSubjectDto OK
     * @throws ApiError
     */
    public static findById10(
id: string,
): CancelablePromise<InterviewContentSubjectDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-subjects/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns InterviewContentSubjectDto OK
     * @throws ApiError
     */
    public static update8(
id: string,
requestBody: InterviewContentSubjectCommand,
): CancelablePromise<InterviewContentSubjectDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/interview-content-subjects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InterviewContentSubjectDto Created
     * @throws ApiError
     */
    public static create9(
requestBody: InterviewContentSubjectCommand,
): CancelablePromise<InterviewContentSubjectDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/interview-content-subjects',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param thematic 
     * @returns InterviewContentSubjectDto OK
     * @throws ApiError
     */
    public static findAllByThematic(
thematic: string,
): CancelablePromise<Array<InterviewContentSubjectDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-subjects/byThematic',
            query: {
                'thematic': thematic,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns InterviewContentSubjectDto OK
     * @throws ApiError
     */
    public static findAll10(): CancelablePromise<Array<InterviewContentSubjectDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-subjects/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
