// @ts-nocheck
import {Column} from 'react-table'
import {TechnicalAdvisorNameCell} from './TechnicalAdvisorNameCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {TechnicalAdvisorActionsCell} from './TechnicalAdvisorActionsCell'
import {TechnicalAdvisorCustomHeader} from './TechnicalAdvisorCustomHeader'
import {TechnicalAdvisorDto} from "../../../../../../services/requests";
import {TechnicalAdvisorBooleanCell} from "./TechnicalAdvisorBooleanCell";
import {TechnicalAdvisorRegionCell} from "./TechnicalAdvisorRegionCell";



const technicalAdvisorsColumns: ReadonlyArray<Column<TechnicalAdvisorDto>> = [
  {
    Header: (props) => <TechnicalAdvisorCustomHeader tableProps={props} title='TABLE.TECHNICAL_ADVISORS.HEADER.NAME' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <TechnicalAdvisorNameCell technicalAdvisor={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TechnicalAdvisorCustomHeader tableProps={props} title='TABLE.TECHNICAL_ADVISORS.HEADER.EMAIL' className='min-w-125px' />,
    accessor: 'email',
  },
  {
    Header: (props) => (
        <TechnicalAdvisorCustomHeader tableProps={props} title='TABLE.TECHNICAL_ADVISORS.HEADER.REGION' className='min-w-125px' />
    ),
    id: 'region',
    accessor: 'region',
    Cell: ({...props}) => <TechnicalAdvisorRegionCell technicalAdvisor={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
        <TechnicalAdvisorCustomHeader tableProps={props} title='TABLE.TECHNICAL_ADVISORS.HEADER.ACTIVE' className='min-w-60px' />
    ),
    id: 'active',
    Cell: ({...props}) => <TechnicalAdvisorBooleanCell bool={props.data[props.row.index].active} />,
  },
  {
    Header: (props) => (
      <TechnicalAdvisorCustomHeader tableProps={props} title='TABLE.TECHNICAL_ADVISORS.HEADER.FULLTIME_AVAILABLE' className='min-w-60px' />
    ),
    id: 'fulltimeAvailable',
    Cell: ({...props}) => <TechnicalAdvisorBooleanCell bool={props.data[props.row.index].fulltimeAvailable} />,
  },
]

export {technicalAdvisorsColumns}
