/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";

export function AsideMenuMain() {
  const intl = useIntl()
    const {currentUser} = useAuth()
  return (
    <>
        {currentUser?.roles?.find(role => role == "ROLE_RECRUITER") && (
            <>
              <AsideMenuItem
                to='/search-for-interview'
                title={intl.formatMessage({id: 'MENU.SEARCH'})}
                fontIcon='bi-search fs-2'
                bsTitle={intl.formatMessage({id: 'MENU.SEARCH'})}
                className='py-2'
              />

                <AsideMenuItem
                    to='/admin/line-of-businesses'
                    title={intl.formatMessage({id: 'MENU.LINE_OF_BUSINESSES'})}
                    fontIcon='bi-building-gear fs-2'
                    bsTitle={intl.formatMessage({id: 'MENU.LINE_OF_BUSINESSES'})}
                    className='py-2 text-center'
                />

            </>
        )}

        {currentUser?.roles?.find(role => role == "ROLE_ADMIN_LOB") && (
        <>

        <AsideMenuItem
            to='/admin/technical-advisors'
            title={intl.formatMessage({id: 'MENU.TECHNICAL_ADVISORS'})}
            fontIcon='bi-people fs-2'
            bsTitle={intl.formatMessage({id: 'MENU.TECHNICAL_ADVISORS'})}
            className='py-2 text-center'
        />


        </>
        )}

        {currentUser?.roles?.find(role => role == "ROLE_ICC") && (
            <>
                <AsideMenuItem
                    to='/admin/interviews-content'
                    title={intl.formatMessage({id: 'MENU.INTERVIEWS_CONTENT'})}
                    fontIcon='bi-patch-question fs-2'
                    bsTitle={intl.formatMessage({id: 'MENU.INTERVIEWS_CONTENT'})}
                    className='py-2 text-center'
                />
            </>
        )}

        {currentUser?.roles?.find(role => role == "ROLE_TA") && (
            <>
            <AsideMenuItem
                to='/interview-content-questions'
                title={intl.formatMessage({id: 'MENU.INTERVIEWS_CONTENT_QUESTIONS'})}
                fontIcon='bi-chat-dots fs-2'
                bsTitle={intl.formatMessage({id: 'MENU.INTERVIEWS_CONTENT_QUESTIONS'})}
                className='py-2 text-center'
            />
            </>
        )}

    </>
  )
}
