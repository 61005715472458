import React, {FC} from "react";
import {
    GetTechnicalAdvisorsForInterview, InterviewContentQuestionDto, InterviewContentQuestionPublicDto,
    JobPositionDto,
    LineOfBusinessDto,
    InterviewContentQuestionSPublicService
} from "../../../../../services/requests";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {ID} from "../../../../../_metronic/helpers";

type Props = {
    question: InterviewContentQuestionDto
    jobPositions: JobPositionDto[]
    activeQuestionPublics: InterviewContentQuestionPublicDto[]
}

const editQuestionPublicSchema = Yup.object().shape({
    questionPublic: Yup.array()
        .min(1)
})

type QuestionPublic = {
    jobPosition: string
    levelOfExpertise: string[]
}
const QuestionPublic: FC<Props> = ({question, activeQuestionPublics, jobPositions}) => {

    const intl = useIntl()

    const questionPublicForForm: string[] = [];
    if(Array.isArray(activeQuestionPublics)) {
        activeQuestionPublics?.forEach(as => {
            const jP = as.jobPosition;
            as.levelsOfExpertise?.forEach(loe => questionPublicForForm[questionPublicForForm.length] = jP + '/' + loe);
        });
    }
    const formikQuestionPublic = useFormik({
        initialValues: {questionPublic: questionPublicForForm},
        validationSchema: editQuestionPublicSchema,
        onSubmit: async (values) => {

            let newQuestionPublic = values.questionPublic.map(as => {
                let asSplit = as.split("/");
                return {id: asSplit[0], levelOfExpertise: asSplit[1] }
            }).reduce((list, { id, levelOfExpertise }) => {
                (list[id] = list[id] || []).push(levelOfExpertise);
                return list;
            }, {});

            jobPositions?.map(async jobPosition => {
                const currentKey = Object.keys(newQuestionPublic).find(elt => elt == jobPosition.id);
                const activeQuestionPublicToWorkOn = activeQuestionPublics?.find(elt => elt.jobPosition === jobPosition.id);
                if(!!currentKey) {
                    if(!!activeQuestionPublicToWorkOn) {

                        let updateNeeded = activeQuestionPublicToWorkOn.levelsOfExpertise.length != newQuestionPublic[currentKey].length;
                        if(!updateNeeded) {
                           updateNeeded = activeQuestionPublicToWorkOn.levelsOfExpertise.filter(loe => !newQuestionPublic[currentKey].find(newLoe => newLoe == loe)).length > 0
                                            || newQuestionPublic[currentKey].filter(loe => !activeQuestionPublicToWorkOn.levelsOfExpertise.find(newLoe => newLoe == loe)).length > 0
                        }
                        if(updateNeeded) {
                            await InterviewContentQuestionSPublicService.update10(activeQuestionPublicToWorkOn.id, {
                                id: activeQuestionPublicToWorkOn.id,
                                interviewContentQuestion: question.id as string,
                                jobPosition: jobPosition.id as string,
                                levelsOfExpertise: newQuestionPublic[currentKey]
                            })
                        }
                    } else {
                        console.log('Perform creation of : '+ jobPosition.id);
                        await InterviewContentQuestionSPublicService.create11({
                            interviewContentQuestion: question.id as string,
                            jobPosition: jobPosition.id as string,
                            levelsOfExpertise: newQuestionPublic[currentKey]
                        });
                    }
                } else {
                    if(!!activeQuestionPublicToWorkOn) {
                        await InterviewContentQuestionSPublicService.delete1(activeQuestionPublicToWorkOn.id);
                    }
                }
            });
        },
    })

    return (
        <>
            {!!jobPositions && jobPositions.length > 0 && (
                <form id='technical-advisor-acceptance-skills' className='form' onSubmit={formikQuestionPublic.handleSubmit} noValidate>
                    {jobPositions.map(jobPosition => (
                        <div className='fv-row mb-15'>
                            <label className='fw-bold fs-6 mb-2'>{jobPosition.name}</label>

                            <div>
                                {Object.keys(GetTechnicalAdvisorsForInterview.levelOfExpertise)?.map((levelOfExpertise) => (
                                    <label className='form-check form-check-inline form-check-solid me-5'>
                                        <input
                                            {...formikQuestionPublic.getFieldProps('questionPublic')}
                                            defaultChecked={!!questionPublicForForm.find(as => as == (jobPosition.id + '/' + levelOfExpertise))}
                                            className='form-check-input'
                                            name='questionPublic'
                                            type='checkbox'
                                            value={jobPosition.id + '/' + levelOfExpertise}
                                        />
                                        <span className='fw-bold ps-2 fs-6'>{levelOfExpertise}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    ))}
                    {formikQuestionPublic.touched.questionPublic && formikQuestionPublic.errors.questionPublic && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formikQuestionPublic.errors.questionPublic}</span>
                            </div>
                        </div>
                    )}
                    <div className='text-center pt-15'>
                        <button
                            type='submit'
                            className='btn btn-primary me-3'
                        >
                            {intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.SAVE_ACCEPTANCE_SKILLS'})}
                        </button>
                    </div>
                </form>
            )}
        </>
    );
}


export {QuestionPublic};