import React, {FC, useRef, useState} from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
    JobPositionDto, JobPositionService,
    LineOfBusinessDto,
} from "../../../../../services/requests";
import {useIntl} from "react-intl";
import {SelectInstance} from "react-select";
import CreatableSelect from "react-select/creatable";

type Props = {
    lineOfBusiness: LineOfBusinessDto,
    jobPositions?: JobPositionDto[],
    refreshJobPositions: Function
}

type Option= {
    label: string
    value: string
}

const JobPositions: FC<Props> = ({jobPositions, lineOfBusiness, refreshJobPositions}) => {

    const intl = useIntl()
    const ref = useRef<SelectInstance>(null);

    const [currentJobPosition, setCurrentJobPosition] = useState<Option>();


    const handleCreate = (inputValue: string) => {
        confirmAlert({
            title: intl.formatMessage({id: 'FORM.JOB_POSITION_EDIT.CONFIRM_ADD_JOB_POSITIONS_TITLE'}),
            message: intl.formatMessage({id: 'FORM.JOB_POSITION_EDIT.CONFIRM_ADD_JOB_POSITIONS_MESSAGE'}, {jobPosition: inputValue}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'COMMON.YES'}),
                    onClick: () => processJobCreation(inputValue)
                },
                {
                    label: intl.formatMessage({id: 'COMMON.NO'})
                }
            ]
        });
    }

    const updateElement = async () => {
        if(currentJobPosition?.value) {
            const originalJobPosition: JobPositionDto | undefined = jobPositions?.find(e => e.id == currentJobPosition?.value)
            await JobPositionService.update5(currentJobPosition?.value,
                {
                    name: currentJobPosition.label,
                    lineOfBusiness: lineOfBusiness.id,
                    id: currentJobPosition.value,
                    parent: originalJobPosition?.parent
                })
            refreshJobPositions()
            setCurrentJobPosition(undefined);
            const selectEl = ref.current;
            if(selectEl) {
                selectEl.clearValue();
            }
        }
    }

    const processJobCreation = async (name: string) => {
        await JobPositionService.create5({name: name, lineOfBusiness: lineOfBusiness.id})
        refreshJobPositions()
    }

    return (
        <div className='w-100'>
            {/* begin::Input group */}
            {!!jobPositions && jobPositions.length > 0 && (
                <div className='row mb-7 w-100'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'FORM.JOB_POSITION_EDIT.EXISTING_JOB_POSITIONS'})}</label>

                    <CreatableSelect
                        ref={ref}
                        name='jobPositions'
                        isClearable
                        options={jobPositions.map(jobPosition => {
                            return {
                                value: jobPosition.id,
                                label: jobPosition.name
                            } as Option;})}
                        onCreateOption={handleCreate}
                        onChange={(e) => setCurrentJobPosition(e as Option)}
                    />
                    {/* end::Label */}
                </div>
            )}
            {/* end::Input group */}

            {currentJobPosition?.value && (
                <div className='row mb-7 w-100'>
                    <input
                        value={currentJobPosition.label}
                        type='text'
                        name='name'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        onChange={e => setCurrentJobPosition({label: e.target.value, value: currentJobPosition?.value})}
                    />
                    <div className='text-center pt-15'>
                        <button
                            className='btn btn-success me-3'
                            onClick={updateElement}
                        >
                            <i className="bi bi-pencil-square"></i>{intl.formatMessage({id: 'COMMON.UPDATE'})}
                        </button>

                        {/*<button*/}
                        {/*    className='btn btn-danger'*/}
                        {/*    onClick={deleteElement}*/}
                        {/*>*/}
                        {/*    <i className="bi bi-trash"></i>Delete*/}
                        {/*</button>*/}
                    </div>
                </div>

            )}


        </div>
    )
}

export {JobPositions}
