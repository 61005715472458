import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery} from "react-query";
import {
    InterviewContentQuestionService, InterviewContentQuestionSPublicService,
    InterviewContentSubjectService,
    InterviewContentThematicService, JobPositionService, TechnicalAdvisorSAcceptanceSkillService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {PageTitle} from "../../../../_metronic/layout/core";
import {QuestionPublic} from "./components/Public";

type Params = {
    id?: ID
    subject: string
    lineOfBusiness: string
}

const QuestionEditPage: FC = () => {
    const location = useLocation();
    const locationState = location?.state as Params;
    const intl = useIntl()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

    const [questionId, setQuestionId] = useState(locationState?.id);
    const [lineOfBusiness, setLineOfBusiness] = useState(locationState?.lineOfBusiness);
    const [subject, setSubject] = useState(locationState?.subject);

    const { data: jobPositions, isLoading: isJobPositionsLoading } = useQuery({
        queryKey: ['jobPositions', lineOfBusiness],
        queryFn: () => {
            // Do something here

            return JobPositionService.findAllByLineOfBusiness2(lineOfBusiness);
        }
    });

    const {data: question, isLoading: isLoading, refetch: refetchQuestion} = useQuery({
            queryKey: ['question', questionId],
            queryFn: () => {
                return InterviewContentQuestionService.findById11(questionId as string);
            },
            enabled: !!questionId
        }
    )
    const { data: questionPublics, isLoading: isQuestionPublicsLoading } = useQuery({
        queryKey: ['questionPublics', questionId],
        queryFn: () => {
            // Do something here

            return InterviewContentQuestionSPublicService.findAllByQuestion(questionId as string);
        },
        enabled: !!questionId
    });


    const everythingLoaded = !isLoading && !isQuestionPublicsLoading && !isJobPositionsLoading;


    const pageTitle = question ?
        intl.formatMessage({id: 'PAGE.QUESTION_EDIT.TITLE.UPDATE'}, {name: question?.shortnameFr})
        : intl.formatMessage({id: 'PAGE.QUESTION_EDIT.TITLE.NEW'});

    const refreshQuestionId = (newId: string) => {
        setQuestionId(newId);
    }

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const changeTab = (newTabIndex: number) => {
        console.log(newTabIndex)
        stepper.current?.goto(newTabIndex)
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {pageTitle}
            </PageTitle>
            <div className='card mb-5'>
                <div className='card-body'>
                    <div
                        ref={stepperRef}
                        className='stepper stepper-links d-flex flex-column pt-5'
                        id='kt_create_account_stepper'
                    >
                        <div className='stepper-nav mb-5'>
                            <div className='stepper-item current' data-kt-stepper-element='nav' onClick={() => changeTab(1)} role="button">
                                <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.QUESTION_EDIT.MENU.MAIN_INFOS'})}</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(2)} role="button">
                                {!!questionId && (
                                    <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.QUESTION_EDIT.MENU.QUESTION_PUBLIC'})}</h3>
                                )}
                            </div>

                        </div>
                    {everythingLoaded && (
                        <div className='mx-auto mw-600px w-100 pt-5'>
                            <div className='current' data-kt-stepper-element='content'>
                                <MainInfos lineOfBusiness={lineOfBusiness} refreshQuestion={refetchQuestion} refreshQuestionId={refreshQuestionId} subject={subject} question={question} />
                            </div>
                            <div data-kt-stepper-element='content'>
                                {question && jobPositions && questionPublics && (
                                 <QuestionPublic jobPositions={jobPositions} activeQuestionPublics={questionPublics} question={question} />
                                )}
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </div>
        </>

    )
}

export {QuestionEditPage}
