/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InterviewContentQuestionCommand } from '../models/InterviewContentQuestionCommand';
import type { InterviewContentQuestionDto } from '../models/InterviewContentQuestionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InterviewContentQuestionService {

    /**
     * @param id 
     * @returns InterviewContentQuestionDto OK
     * @throws ApiError
     */
    public static findById11(
id: string,
): CancelablePromise<InterviewContentQuestionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-questions/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns InterviewContentQuestionDto OK
     * @throws ApiError
     */
    public static update9(
id: string,
requestBody: InterviewContentQuestionCommand,
): CancelablePromise<InterviewContentQuestionDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/interview-content-questions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InterviewContentQuestionDto Created
     * @throws ApiError
     */
    public static create10(
requestBody: InterviewContentQuestionCommand,
): CancelablePromise<InterviewContentQuestionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/interview-content-questions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param subject 
     * @returns InterviewContentQuestionDto OK
     * @throws ApiError
     */
    public static findAllBySubject(
subject: string,
): CancelablePromise<Array<InterviewContentQuestionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-questions/bySubject',
            query: {
                'subject': subject,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns InterviewContentQuestionDto OK
     * @throws ApiError
     */
    public static findAll11(): CancelablePromise<Array<InterviewContentQuestionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/interview-content-questions/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
