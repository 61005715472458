import React, {FC} from "react";
import {useIntl} from "react-intl";
import {useQuery} from "react-query";
import {LineOfBusinessService} from "../../../../services/requests";
import {Link} from "react-router-dom";
import {useAuth} from "../../../modules/auth";


const LineOfBusinessesListPageForThematics: FC = () => {
    const intl = useIntl()
    const {currentUser} = useAuth()
    const { data: lineOfBusinesses } = useQuery({
        queryKey: ['lineOfBusinesses'],
        queryFn: LineOfBusinessService.findAll4,
    })

    const userHasLineOfBusiness = (lineOfBusinessToCheck: string) => {
        return(
            ((!currentUser?.lineOfBusinesses || currentUser?.lineOfBusinesses.length == 0) || !!currentUser?.lineOfBusinesses?.find(lob => lob == lineOfBusinessToCheck) )
        )

    }

    const lineOfBusinessesUsed = lineOfBusinesses?.filter(lob => userHasLineOfBusiness(lob.id));



    return (
     <>
         {/* begin::Row */}
         {lineOfBusinesses && (
             <div className='g-5 gx-xxl-8 mt-5'>
                 <div className={'card'}>
                     {/* begin::Header */}
                     <div className='card-header border-0 pt-5'>
                         <h3 className='card-title align-items-start flex-column'>
                             <span className='card-label fw-bold fs-3 mb-1 text-primary'>{intl.formatMessage({id: 'TABLE.LINE_OF_BUSINESSES.TITLE'})}</span>
                         </h3>
                     </div>
                     {/* end::Header */}
                     {/* begin::Body */}
                     <div className='card-body py-3'>
                         {/* begin::Table container */}
                         <div className='table-responsive'>
                             {/* begin::Table */}
                             <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                 {/* begin::Table head */}
                                 <thead>
                                 <tr className='fw-bold text-muted'>
                                     <th className='min-w-325px'>{intl.formatMessage({id: 'TABLE.LINE_OF_BUSINESSES.HEADER.NAME'})}</th>
                                 </tr>
                                 </thead>
                                 {/* end::Table head */}
                                 {/* begin::Table body */}
                                 <tbody>
                                 {lineOfBusinessesUsed?.map((lineOfBusiness) => (
                                     <tr key={lineOfBusiness.id}>
                                         <td>
                                             <div className='d-flex align-items-center'>
                                                 <div className='d-flex justify-content-start flex-column'>
                                                        <Link to='/admin/interviews-content/thematics' className='text-dark fw-bold text-hover-primary fs-6' state={{lineOfBusiness: lineOfBusiness.id}} >{lineOfBusiness.name}</Link>
                                                 </div>
                                             </div>
                                         </td>
                                     </tr>
                                 ))}
                                 </tbody>
                                 {/* end::Table body */}
                             </table>
                             {/* end::Table */}
                         </div>
                         {/* end::Table container */}
                     </div>
                     {/* begin::Body */}
                 </div>
             </div>
         )}
         {/* end::Row */}
     </>
    )
}

export {LineOfBusinessesListPageForThematics};