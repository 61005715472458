/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InterviewTechnicalAdvisorCommand = {
    id?: string;
    interview: string;
    technicalAdvisor: string;
    currentStatus?: InterviewTechnicalAdvisorCommand.currentStatus;
};

export namespace InterviewTechnicalAdvisorCommand {

    export enum currentStatus {
        CREATED = 'CREATED',
        ACCEPTED = 'ACCEPTED',
        REFUSED = 'REFUSED',
        DECLINED_BY_RECRUITER = 'DECLINED_BY_RECRUITER',
    }


}
