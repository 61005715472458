import {useListView} from '../../core/ListViewProvider'
import {TechnicalAdvisorsListToolbar} from './TechnicalAdvisorsListToolbar'
import {TechnicalAdvisorsSearchComponent} from './TechnicalAdvisorsSearchComponent'
import {LineOfBusinessFilter} from "./LineOfBusinessFilter";

const TechnicalAdvisorsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
        <LineOfBusinessFilter />
        <TechnicalAdvisorsSearchComponent />

      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <TechnicalAdvisorsListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TechnicalAdvisorsListHeader}
