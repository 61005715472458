/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetTechnicalAdvisorsForInterview = {
    lineOfBusiness: string;
    jobPosition: string;
    technicalWords?: Array<string>;
    levelOfExpertise: GetTechnicalAdvisorsForInterview.levelOfExpertise;
    lang: GetTechnicalAdvisorsForInterview.lang;
};

export namespace GetTechnicalAdvisorsForInterview {

    export enum levelOfExpertise {
        A = 'A',
        B = 'B',
        C = 'C',
        D = 'D',
        E = 'E',
    }

    export enum lang {
        FR = 'FR',
        EN = 'EN',
    }


}
