/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobPositionCommand } from '../models/JobPositionCommand';
import type { JobPositionDto } from '../models/JobPositionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JobPositionService {

    /**
     * @param id 
     * @returns JobPositionDto OK
     * @throws ApiError
     */
    public static findById5(
id: string,
): CancelablePromise<JobPositionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/jobpositions/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns JobPositionDto OK
     * @throws ApiError
     */
    public static update5(
id: string,
requestBody: JobPositionCommand,
): CancelablePromise<JobPositionDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/jobpositions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns JobPositionDto Created
     * @throws ApiError
     */
    public static create5(
requestBody: JobPositionCommand,
): CancelablePromise<JobPositionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/jobpositions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param lineOfBusiness 
     * @returns JobPositionDto OK
     * @throws ApiError
     */
    public static findAllByLineOfBusiness2(
lineOfBusiness: string,
): CancelablePromise<Array<JobPositionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/jobpositions/byLineOfBusiness',
            query: {
                'lineOfBusiness': lineOfBusiness,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns JobPositionDto OK
     * @throws ApiError
     */
    public static findAll5(): CancelablePromise<Array<JobPositionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/jobpositions/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
