/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TechnicalWordCommand } from '../models/TechnicalWordCommand';
import type { TechnicalWordDto } from '../models/TechnicalWordDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TechnicalWordService {

    /**
     * @param id 
     * @returns TechnicalWordDto OK
     * @throws ApiError
     */
    public static findById(
id: string,
): CancelablePromise<TechnicalWordDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-words/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns TechnicalWordDto OK
     * @throws ApiError
     */
    public static update(
id: string,
requestBody: TechnicalWordCommand,
): CancelablePromise<TechnicalWordDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/technical-words/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TechnicalWordDto Created
     * @throws ApiError
     */
    public static create(
requestBody: TechnicalWordCommand,
): CancelablePromise<TechnicalWordDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/technical-words',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param lineOfBusiness 
     * @returns TechnicalWordDto OK
     * @throws ApiError
     */
    public static findAllByLineOfBusiness(
lineOfBusiness: string,
): CancelablePromise<Array<TechnicalWordDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-words/byLineOfBusiness',
            query: {
                'lineOfBusiness': lineOfBusiness,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns TechnicalWordDto OK
     * @throws ApiError
     */
    public static findAll(): CancelablePromise<Array<TechnicalWordDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/technical-words/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
