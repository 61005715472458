/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InterviewTechnicalWordCommand } from './InterviewTechnicalWordCommand';

export type InterviewCommand = {
    id?: string;
    recruiterName: string;
    recruiterEmail: string;
    applicantName: string;
    jobPosition: string;
    technicalWords?: Array<InterviewTechnicalWordCommand>;
    technicalAdvisorDesignated?: string;
    levelOfExpertise: InterviewCommand.levelOfExpertise;
    additionalInformations?: string;
    urgent?: boolean;
};

export namespace InterviewCommand {

    export enum levelOfExpertise {
        A = 'A',
        B = 'B',
        C = 'C',
        D = 'D',
        E = 'E',
    }


}
