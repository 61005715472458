import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import React from "react";
import {useIntl} from "react-intl";
import {LineOfBusinessesListPageForThematics} from "./line-of-businesses-list-for-thematics/LineOfBusinessesListPageForThematics";
import {ThematicsListPage} from "./thematics-list/ThematicsListPage";
import {ThematicEditPage} from "./thematic-edit/ThematicEditPage";
import {SubjectsListPage} from "./subjects-list/SubjectsListPage";
import {SubjectEditPage} from "./subject-edit/SubjectEditPage";
import {QuestionsListPage} from "./questions-list/QuestionsListPage";
import {QuestionEditPage} from "./question-edit/QuestionEditPage";


const InterviewsContentRoutesDefinition = () => {
    const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PAGE.INTERVIEWS_CONTENT_THEMATICS.TITLE'})}</PageTitle>
              <LineOfBusinessesListPageForThematics />
            </>
          }
        />
      </Route>
        <Route
            path='thematics'
            element={
                <>
                    <ThematicsListPage />
                </>
            }
        />
        <Route
            path='thematics/edit'
            element={
                <>
                    <ThematicEditPage />
                </>
            }
        />
        <Route
            path='subjects'
            element={
                <>
                    <SubjectsListPage />
                </>
            }
        />
        <Route
            path='subjects/edit'
            element={
                <>
                    <SubjectEditPage />
                </>
            }
        />
        <Route
            path='questions'
            element={
                <>
                    <QuestionsListPage />
                </>
            }
        />
        <Route
            path='questions/edit'
            element={
                <>
                    <QuestionEditPage />
                </>
            }
        />
      <Route index element={<Navigate to='/admin/interviews-content/list' />} />
    </Routes>
  )
}

export default InterviewsContentRoutesDefinition
