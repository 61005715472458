/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {TechnicalAdvisorDto} from "../../../../../../services/requests";
import {FormattedMessage} from "react-intl";

type Props = {
  technicalAdvisor: TechnicalAdvisorDto
}

const TechnicalAdvisorRegionCell: FC<Props> = ({technicalAdvisor}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
        <span><FormattedMessage id="ENUM.REGION" values={{ region: technicalAdvisor.region }} /></span>
    </div>
  </div>
)

export {TechnicalAdvisorRegionCell}
