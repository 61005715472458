/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TechnicalAdvisorForInterview = {
    id: string;
    createdAt?: string;
    updatedAt?: string;
    name: string;
    email: string;
    employeeId: string;
    langs: Array<'FR' | 'EN'>;
    lineOfBusiness: string;
    technicalWords: Array<string>;
    region: TechnicalAdvisorForInterview.region;
    nbHoursInterviewsCurrentYear: number;
    fulltimeAvailable: boolean;
    active: boolean;
    blocked: boolean;
    blockedComment?: string;
    matchingTechnicalWords?: Array<string>;
    correspondingScore: number;
    warningNbHoursInterviews: boolean;
};

export namespace TechnicalAdvisorForInterview {

    export enum region {
        QUEBEC = 'QUEBEC',
        CANADA = 'CANADA',
        USA = 'USA',
        FRANCE = 'FRANCE',
        MAROC = 'MAROC',
        INDIA = 'INDIA',
    }


}
