/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InterviewTechnicalAdvisorDto = {
    id: string;
    createdAt?: string;
    updatedAt?: string;
    interview?: string;
    technicalAdvisor?: string;
    currentStatus?: InterviewTechnicalAdvisorDto.currentStatus;
};

export namespace InterviewTechnicalAdvisorDto {

    export enum currentStatus {
        CREATED = 'CREATED',
        ACCEPTED = 'ACCEPTED',
        REFUSED = 'REFUSED',
        DECLINED_BY_RECRUITER = 'DECLINED_BY_RECRUITER',
    }


}
