import React, {FC, useEffect, useRef, useState} from 'react'
import {ID, KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'

import {useIntl} from "react-intl";
import {MainInfos} from "./components/MainInfos";
import {useQuery} from "react-query";
import {
    JobPositionService,
    LineOfBusinessService,
    TechnicalWordService
} from "../../../../services/requests";
import {useLocation} from "react-router";
import {PageTitle} from "../../../../_metronic/layout/core";
import {JobPositions} from "./components/JobPositions";
import {TechnicalWords} from "./components/TechnicalWords";

type Params = {
    id?: ID
}

const LineOfBusinessEditPage: FC = () => {
    const location = useLocation();
    const locationState = location?.state as Params;
    const intl = useIntl()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

    const [lineOfBusinessId, setLineOfBusinessId] = useState(locationState?.id);


    const {data: lineOfBusiness, isLoading: isLineOfBusinessLoading, refetch: refetchLineOfBusiness} = useQuery({
            queryKey: ['lineOfBusiness', lineOfBusinessId],
            queryFn: () => {
                return LineOfBusinessService.findById4(lineOfBusinessId as string);
            },
            enabled: !!lineOfBusinessId
        }
    )

    const { data: technicalWords, isLoading: isTechnicalWordsLoading, refetch: refreshTechnicalWords } = useQuery({
        queryKey: ['technicalWords', lineOfBusinessId],
        queryFn: () => {
            // Do something here

            return TechnicalWordService.findAllByLineOfBusiness(lineOfBusinessId as string);
        },
        enabled: !!lineOfBusinessId
    });

    const { data: jobPositions, isLoading: isJobPositionsLoading, refetch: refreshJobPositions } = useQuery({
        queryKey: ['jobPositions', lineOfBusinessId],
        queryFn: () => {
            // Do something here

            return JobPositionService.findAllByLineOfBusiness2(lineOfBusinessId as string);
        },
        enabled: !!lineOfBusinessId
    });

    const everythingLoaded = !isLineOfBusinessLoading && !isJobPositionsLoading && !isTechnicalWordsLoading;


    const pageTitle = lineOfBusiness ?
        intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESS_EDIT.TITLE.UPDATE'}, {name: lineOfBusiness?.name})
        : intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESS_EDIT.TITLE.NEW'});

    const refreshLineOfBusinessId = (newLineOfBusinessId: string) => {
        setLineOfBusinessId(newLineOfBusinessId);
    }


    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const changeTab = (newTabIndex: number) => {
        stepper.current?.goto(newTabIndex)
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {pageTitle}
            </PageTitle>
            <div className='card mb-5'>
                <div className='card-body'>
                    <div
                        ref={stepperRef}
                        className='stepper stepper-links d-flex flex-column pt-5'
                        id='kt_create_account_stepper'
                    >
                        <div className='stepper-nav mb-5'>
                            <div className='stepper-item current' data-kt-stepper-element='nav' onClick={() => changeTab(1)} role="button">
                               <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESS_EDIT.MENU.MAIN_INFOS'})}</h3>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(2)} role="button">
                                    {!!lineOfBusinessId && (
                                        <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESS_EDIT.MENU.JOB_POSITIONS'})}</h3>
                                    )}
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => changeTab(3)} role="button">
                                    {!!lineOfBusinessId && (
                                        <h3 className='stepper-title'>{intl.formatMessage({id: 'PAGE.LINE_OF_BUSINESS_EDIT.MENU.TECHNICAL_WORDS'})}</h3>
                                    )}
                            </div>


                        </div>
                        {everythingLoaded && (
                            <div className='mx-auto mw-600px w-100 pt-5'>
                                <div className='current' data-kt-stepper-element='content'>
                                   <MainInfos refreshLineOfBusiness={refetchLineOfBusiness} refreshLineOfBusinessId={refreshLineOfBusinessId} lineOfBusiness={lineOfBusiness} />
                                </div>
                                <div data-kt-stepper-element='content'>
                                    {lineOfBusiness && (
                                        <JobPositions refreshJobPositions={refreshJobPositions} lineOfBusiness={lineOfBusiness} jobPositions={jobPositions} />
                                    )}
                                </div>
                                <div data-kt-stepper-element='content'>
                                    {lineOfBusiness && (
                                        <TechnicalWords refreshTechnicalWords={refreshTechnicalWords} lineOfBusiness={lineOfBusiness} technicalWords={technicalWords} />
                                    )}
                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>

    )
}

export {LineOfBusinessEditPage}
