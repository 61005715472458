/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthRequestDto } from '../models/AuthRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthenticationService {

    /**
     * @param requestBody 
     * @returns string OK
     * @throws ApiError
     */
    public static authenticateAndGetToken(
requestBody: AuthRequestDto,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/generateToken',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static welcome(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/welcome',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
