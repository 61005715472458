/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserInfoDto } from '../models/UserInfoDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param id 
     * @param requestBody 
     * @returns UserInfoDto OK
     * @throws ApiError
     */
    public static updateUser(
id: string,
requestBody: UserInfoDto,
): CancelablePromise<UserInfoDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/updateUser/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserInfoDto OK
     * @throws ApiError
     */
    public static addNewUser(
requestBody: UserInfoDto,
): CancelablePromise<UserInfoDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/addNewUser',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static userProfile(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/userProfile',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns UserInfoDto OK
     * @throws ApiError
     */
    public static getCurrentUser(): CancelablePromise<UserInfoDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/current',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static adminProfile(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/admin/adminProfile',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
